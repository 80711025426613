.page {
  background-color: #f5f5f5;
  width: 750px;
  margin: 10px auto;
  padding: 0;
}

.line-list {
  color: #555;
  padding: 0 !important;
  font-family: courier, monospace;
}

.line-list li {
  list-style: none;
  border-bottom: 1px dotted #ccc;
  text-indent: 25px;
  height: 30px;
  text-transform: capitalize;
}

.line-list li:hover {
  background-color: #f0f0f0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.header {
  height: 40px;
}

.footer {
  height: 30px;
}

.left-margins {
  border-left: 1px solid #ffaa9f;
  border-right: 1px solid #ffaa9f;
  width: 2px;
  float: left;
  height: 872px;
  margin-left: 75px;
}
